import React from "react";
import styled from "styled-components";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollStandardAnimation } from "../../utilities/animation-helper";
import { Headline1, Paragraph, Headline2 } from "../typography";

interface IntroProps {
  headline: string;
  subHeadline: string;
  content: string;
}

export const Intro: React.FC<IntroProps> = ({ headline, subHeadline, content }) => {
  const matchMobile = useMatchMedia("(max-width: 767.9px)");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const headline1Ref = React.useRef<HTMLHeadingElement>(null);
  const headline2Ref = React.useRef<HTMLHeadingElement>(null);
  const paragraphRef = React.useRef<HTMLParagraphElement>(null);

  React.useLayoutEffect(() => {
    const [timeline] = onScrollStandardAnimation(
      containerRef.current,
      [headline1Ref.current, headline2Ref.current, paragraphRef.current],
      matchMobile
    );

    return () => {
      timeline?.kill();
    };
  }, [matchMobile]);

  return (
    <Content ref={containerRef}>
      <Headline1 ref={headline1Ref} dangerouslySetInnerHTML={{ __html: headline }} />
      <ContentInner>
        <SubHeadlineWrapper>
          <Headline2 ref={headline2Ref} dangerouslySetInnerHTML={{ __html: subHeadline }} />
        </SubHeadlineWrapper>
        <Paragraph as="div" ref={paragraphRef} dangerouslySetInnerHTML={{ __html: content }} />
      </ContentInner>
    </Content>
  );
};

const Content = styled.div.attrs({
  className: "w-full px-5 pt-36 tablet:px-40 tablet:pt-12 relative",
})``;
const ContentInner = styled.div.attrs({ className: "max-w-lg tablet:pr-4" })``;
const SubHeadlineWrapper = styled.div.attrs({ className: "pr-12" })``;

import React from "react";
import styled from "styled-components";
import { ImageDataInterface } from "../../graphql/common";
import { PimcoreImage } from "../pimcore-image";
import { Intro } from "./intro";

interface MainSectionProps {
  headline: string;
  subHeadline: string;
  content: string;
  background: ImageDataInterface;
  backgroundMobile?: ImageDataInterface;
}

const MainSection: React.FC<MainSectionProps> = ({
  headline,
  subHeadline,
  content,
  background,
  backgroundMobile,
  children,
}) => (
  <Wrapper>
    <ImageWrapper>
      <PimcoreImage image={background} mobileImage={backgroundMobile} loading="eager" withAspectRatio />
    </ImageWrapper>
    <Intro headline={headline} subHeadline={subHeadline} content={content} />
    {children}
  </Wrapper>
);

const Wrapper = styled.section.attrs({ className: `relative scrollspy` })``;
const ImageWrapper = styled.div.attrs({
  className: "absolute left-0 right-0 top-0 -z-x ",
})``;

export default MainSection;

import React from "react";
import styled from "styled-components";
import {
  createTimeline,
  onScrollAnimationTo,
} from "../../utilities/animation-helper";
import { theme } from "../../styles/styles";
import { useMatchMedia } from "../../hooks/use-match-media";
import gsap from "gsap";
import { ImageDataInterface } from "../../graphql/common";
import { PimcoreImage } from "../pimcore-image";

interface ColProps {
  $x: string;
  $y: string;
  $w?: string;
  $h?: string;
  $align?: string;
}

interface SectionGalleryProps {
  images: Array<{ image: ImageDataInterface; }>;
}

interface GridConfig {
  x: string;
  y: string;
  w?: string;
  h?: string;
  align?: string;
}

const galleryGridConfig: Array<GridConfig> = [
  {
    x: "col-start-4 tablet:col-start-8",
    y: "row-start-1",
    w: "tablet:col-span-3",
    h: "row-span-3 tablet:row-span-2",
  },
  {
    x: "col-start-2 tablet:col-start-5",
    y: "row-start-2",
    w: "col-span-2 tablet:col-span-3",
    h: "tablet:row-span-2",
    align: "tablet:self-end",
  },
  {
    x: "hidden tablet:block col-start-8",
    y: "row-start-3",
    w: "col-span-2",
    h: "row-span-2",
  },
  {
    x: "hidden tablet:block col-start-10",
    y: "row-start-3",
    w: "col-span-2",
    h: "row-span-5",
  },
  {
    x: "hidden tablet:block col-start-8",
    y: "row-start-5",
    w: "col-span-2",
    h: "row-span-2",
  },
  {
    x: "col-start-3 tablet:col-start-6",
    y: "row-start-3 tablet:row-start-4",
    w: "tablet:col-span-2",
    h: "tablet:row-span-4",
  },
  {
    x: "hidden tablet:block col-start-3",
    y: "row-start-4",
    w: "col-span-3",
    h: "row-span-2",
  },
  {
    x: "col-start-1 tablet:col-start-4",
    y: "row-start-3 tablet:row-start-6",
    w: "col-span-2",
    h: "tablet:row-span-3",
  },
  {
    x: "hidden tablet:block col-start-1",
    y: "row-start-6",
    w: "col-span-3",
    h: "row-span-2",
  },
];

const setupAnimation = (
  trigger: React.RefObject<HTMLDivElement>,
  container: React.RefObject<HTMLDivElement>,
  images: React.RefObject<Array<HTMLDivElement | null>>,
  isMobile: boolean
) => {

  let allElements: Array<HTMLElement | null> = [container?.current];

  if (isMobile) {
    allElements.push(images?.current?.[0] || null);
    allElements.push(images?.current?.[1] || null);
    allElements.push(images?.current?.[5] || null);
    allElements.push(images?.current?.[7] || null);
  } else {
    images.current?.forEach(el => allElements.push(el));
  }

  gsap.set(allElements, { clearProps: true });


  const containerAnimation = onScrollAnimationTo(
    container,
    { x: "20rem", y: "-10rem" },
    {
      trigger: trigger.current,
      scrub: 0.5,
      start: isMobile ? "top bottom" : "top-=25% bottom",
      end: isMobile ? "center+=20% top" : "bottom+=20% top",
    }
  );

  const timeline = createTimeline({
    scrollTrigger: {
      trigger: trigger.current,
      start: isMobile ? "top-=10% bottom" : "top-=25% bottom",
      end: isMobile ? "center-=20% top" : "center+=30% top",
      scrub: 0.5,
    }
  });

  if (isMobile) {
    [
      images?.current?.[0],
      images?.current?.[1],
      images?.current?.[5],
      images?.current?.[7],
    ].forEach((image, index) => {
      if (image) {
        timeline.to(
          image,
          { y: "-1rem", duration: 0.3 },
          index > 1 ? "=-0.2" : undefined
        );
      }
    });
  } else {
    timeline.to(images?.current, { y: "-4rem", duration: 0.6, stagger: 0.1 });
  }

  return [containerAnimation, timeline];
};

const SectionGallery: React.FC<SectionGalleryProps> = ({ images }) => {
  const matchMobile = useMatchMedia("(max-width: 767.8px)");
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const galleryRef = React.useRef<HTMLDivElement>(null);
  const imgRefs = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    imgRefs.current = imgRefs?.current?.slice(0, images.length);
  }, [images.length]);

  React.useEffect(() => {
    const [containerAnimation, timeline] = setupAnimation(
      wrapperRef,
      galleryRef,
      imgRefs,
      matchMobile
    );
    return () => {
      containerAnimation?.kill();
      timeline?.kill();
    };
  }, [matchMobile]);

  return (
    <GalleryWrapper ref={wrapperRef}>
      <GridWrapper ref={galleryRef}>
        <Grid>
          {images.slice(0, 9).map((image, index) => (
            <Col
              key={`iw_${index}`}
              $x={galleryGridConfig[index].x}
              $y={galleryGridConfig[index].y}
              $w={galleryGridConfig[index].w}
              $h={galleryGridConfig[index].h}
              $align={galleryGridConfig[index].align}
            >
              <ImageWrapper ref={(img) => (imgRefs.current[index] = img)}>
                <Image>
                  <PimcoreImage image={image.image} withAspectRatio />
                </Image>
              </ImageWrapper>
            </Col>
          ))}
        </Grid>
      </GridWrapper>
    </GalleryWrapper>
  );
};

const GalleryWrapper = styled.div.attrs({ className: "relative" })`
  height: 42rem;

  @media ${theme.mediaQueries.tablet} {
    height: 85rem;
  }
`;

const GridWrapper = styled.div.attrs({ className: "absolute" })`
  top: 10rem;
  left: -25rem;

  @media ${theme.mediaQueries.tablet} {
    top: -10rem;
    left: -30rem;
  }
`;

const Grid = styled.div.attrs({
  className: "grid gap-2.5 relative h-full w-full tablet:gap-4",
})`
  grid-template-columns: 1fr 0.1fr 1fr 1fr;
  grid-template-rows: 0.2fr 0.25fr 1fr;
  width: 45rem;

  @media ${theme.mediaQueries.tablet} {
    grid-template-columns: repeat(11, minmax(0, 1fr));
    grid-template-rows: 1fr 0.25fr 0.1fr repeat(2, minmax(0, 0.25fr)) 0.1fr repeat(
        2,
        minmax(0, 0.5fr)
      );
    width: 120rem;
  }
`;

const Col = styled.div.attrs<ColProps>(
  ({
    $x,
    $y,
    $w = "tablet:col-span-1",
    $h = "tablet:row-span-1",
    $align = "",
  }) => ({ className: `${$x} ${$y} ${$w} ${$h} ${$align}` })
) <ColProps>``;

const ImageWrapper = styled.div.attrs({
  className: "rounded-lg overflow-hidden h-full w-full",
})``;

const Image = styled.div.attrs({ className: "rounded-lg overflow-hidden" })``;

export default SectionGallery;
